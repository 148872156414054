import React from "react";
import { __, decode, encode, isMobile } from "../../../../Helper";
import { Button, Dropdown } from "react-bootstrap";
import { Alert, Box, Snackbar, Tab, Tabs, Typography } from "@mui/material";
import SavingsIcon from "@mui/icons-material/Savings";
import InventoryIcon from "@mui/icons-material/Inventory";
import coins from "../../../coins";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  depositWithdrawInstance,
  getERC20TokenInstance,
  convertTokenBalanceString,
  handleSendTransaction,
  handleGetResult,
  TRANSACTION_STATUS_TYPE,
  bscTokenApproval,
} from "../../../../Static/contract";
import { ethers, BigNumber } from "ethers";
import axios from "axios";

class DepositAndWithdraw extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      currentCoin: "ALPH",
      credits: {},
      list: [],
      tokenAddress: "",
      openSnack: false,
      currentAddress: "",
      erc20TokenInstance: {},
      addressTokenBalance: "5.0000",
      addressTokenBalanceWei: "",
      amountInput: "",
      transactionStatus: "",
      errorMsg: "",
      currentTokenCreditsBalance: "0.0000",
      pendingTransaction: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getCurrentAddress();
    this.setState({ currentCoin: "ALPH" });
    socket.emit(C.CREDIT, encode({ coin: "alph" }));
    socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentCoin === "ALPH" &&
      this.state.addressTokenBalance === "0.0000" &&
      this.state.addressTokenBalanceWei === "" &&
      this.state.currentTokenCreditsBalance === "0.0000" &&
      this.state.tokenAddress === ""
    ) {
      this.getDefaultBalance();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getCreditAndCoins = (data) => {
    if (this._isMounted) {
      let { credit } = data;
      this.setState({ credits: credit });
      this.setUpCoins();
    }
  };

  getCurrentAddress = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    this.setState({ currentAddress: address });
  };

  getDefaultBalance = async () => {
    if (!this.state.currentAddress || !this.state.credits) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const balanceWei = await provider.getBalance(this.state.currentAddress);
    const balance = convertTokenBalanceString(balanceWei);

    this.setState({
      addressTokenBalance: balance,
      addressTokenBalanceWei: balanceWei,
      currentTokenCreditsBalance: this.state.credits.bnb,
      tokenAddress: process.env.REACT_APP_ADDRESS_BNB,
    });
  };

  loadAddress = async (active, coin) => {
    if (this._isMounted) {
      this.setState({ currentCoin: coin });

      let tokenInstance = {};
      let erc20TokenAddress = "";
      let creditsBalance = "0.0000";

      switch (coin) {
        case "ALPH":
          erc20TokenAddress = process.env.REACT_APP_ADDRESS_BNB;
          tokenInstance = getERC20TokenInstance(
            process.env.REACT_APP_ADDRESS_BNB
          );
          creditsBalance = this.state.credits.bnb;
          break;

        case "BUSD":
          erc20TokenAddress = process.env.REACT_APP_ADDRESS_BUSD;
          tokenInstance = getERC20TokenInstance(
            process.env.REACT_APP_ADDRESS_BUSD
          );
          creditsBalance = this.state.credits.busd;
          break;

        case "DOGE":
          erc20TokenAddress = process.env.REACT_APP_ADDRESS_DODGE;
          tokenInstance = getERC20TokenInstance(
            process.env.REACT_APP_ADDRESS_DODGE
          );
          creditsBalance = this.state.credits.doge;
          break;

        case "USDC":
          erc20TokenAddress = process.env.REACT_APP_ADDRESS_USDC;
          tokenInstance = getERC20TokenInstance(
            process.env.REACT_APP_ADDRESS_USDC
          );
          creditsBalance = this.state.credits.usdc;
          break;

        default:
          tokenInstance = {};
          erc20TokenAddress = "";
          this.setState({
            addressTokenBalance: "0.0000",
            addressTokenBalanceWei: "",
          });
          break;
      }

      this.setState({
        erc20TokenInstance: tokenInstance,
        tokenAddress: erc20TokenAddress,
        currentTokenCreditsBalance: creditsBalance,
      });

      if (coin === "ALPH") {
        const provider = new ethers.providers.Web3Provider(
          window.ethereum,
          "any"
        );

        const balanceWei = await provider.getBalance(this.state.currentAddress);
        const balance = convertTokenBalanceString(balanceWei);
        this.setState({
          addressTokenBalance: balance,
          addressTokenBalanceWei: balanceWei,
        });
        return;
      }

      if (
        !tokenInstance ||
        Object.keys(tokenInstance).length === 0 ||
        !erc20TokenAddress
      )
        return;
      const balanceWei = await tokenInstance.balanceOf(
        this.state.currentAddress
      );
      const balance = convertTokenBalanceString(balanceWei);
      this.setState({
        addressTokenBalance: balance,
        addressTokenBalanceWei: balanceWei,
      });
    }
  };

  handleChangeInput = (event) => {
    this.setState({ amountInput: event.target.value, errorMsg: "" });
  };

  handleCheckApprove = async () => {
    if (this.state.currentCoin === "ALPH") {
      return true;
    } else {
      try {
        const currentAllowance = await this.state.erc20TokenInstance.allowance(
          this.state.currentAddress,
          process.env.REACT_APP_ADDRESS_DEPOSIT_WITHDRAW
        );

        const approvalAmountWei = ethers.utils.parseEther(
          this.state.amountInput.toString()
        );
        const bigNumberApprovalAmountWei = BigNumber.from(approvalAmountWei);

        return currentAllowance.gte(bigNumberApprovalAmountWei);
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };

  handleApprove = async () => {
    try {
      const transactionData = await bscTokenApproval(
        this.state.erc20TokenInstance,
        this.state.tokenAddress,
        process.env.REACT_APP_ADDRESS_DEPOSIT_WITHDRAW,
        this.state.amountInput,
        this.state.currentAddress
      );

      const transactionHash = await handleSendTransaction({
        transactionData: transactionData,
        walletAddress: this.state.currentAddress,
      });

      const transactionResult = await handleGetResult(transactionHash);

      if (transactionResult.status === TRANSACTION_STATUS_TYPE.complete) {
        this.handleDeposit();
      } else if (transactionResult.status === TRANSACTION_STATUS_TYPE.failed) {
        this.setState({
          transactionStatus: TRANSACTION_STATUS_TYPE.failed,
          amountInput: "",
          openSnack: true,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        transactionStatus: TRANSACTION_STATUS_TYPE.failed,
        amountInput: "",
        openSnack: true,
      });
    }
  };

  handleClickDeposit = async () => {
    if (!this.state.amountInput || Number(this.state.amountInput) <= 0) {
      this.setState({ errorMsg: "Invalid input amount" });

      return;
    }
    const approvalAmountWei = ethers.utils.parseEther(
      this.state.amountInput.toString()
    );

    if (approvalAmountWei.gt(this.state.addressTokenBalanceWei)) {
      this.setState({ errorMsg: "Insufficient balance" });

      return;
    }

    this.setState({ transactionStatus: TRANSACTION_STATUS_TYPE.loading });

    const isApprove = await this.handleCheckApprove();

    if (isApprove) {
      this.handleDeposit();
    } else {
      this.handleApprove();
    }
  };

  handleDeposit = async () => {
    try {
      const depositAmountWei = ethers.utils.parseEther(
        this.state.amountInput.toString()
      );

      const data = depositWithdrawInstance.interface.encodeFunctionData(
        "deposit",
        [this.state.tokenAddress, depositAmountWei]
      );

      const transactionData = {
        from: this.state.currentAddress,
        data: data,
        to: process.env.REACT_APP_ADDRESS_DEPOSIT_WITHDRAW,
        value: depositAmountWei,
      };

      if (this.state.currentCoin !== "ALPH") {
        delete transactionData.value;
      }

      const transactionHash = await handleSendTransaction({
        transactionData: transactionData,
        walletAddress: this.state.currentAddress,
      });

      const transactionResult = await handleGetResult(transactionHash);

      if (transactionResult.status === TRANSACTION_STATUS_TYPE.complete) {
        this.setState({
          transactionStatus: TRANSACTION_STATUS_TYPE.complete,
        });
      } else if (transactionResult.status === TRANSACTION_STATUS_TYPE.failed) {
        this.setState({
          transactionStatus: TRANSACTION_STATUS_TYPE.failed,
        });
      }

      this.setState({ amountInput: "", openSnack: true });
    } catch (e) {
      console.log(e);
      this.setState({
        transactionStatus: TRANSACTION_STATUS_TYPE.failed,
        amountInput: "",
        openSnack: true,
      });
    }
  };

  handleGetOrderData = async () => {
    const token = localStorage.getItem("token");
    const getOrderDataUrl = `${process.env.REACT_APP_SERVICE_URL}/withdraw/get-order`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = {};

    try {
      response = await axios.post(
        getOrderDataUrl,
        {
          amount: this.state.amountInput.toString(),
          token: this.state.currentCoin.toString().toLowerCase(),
        },
        config
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      this.setState({
        transactionStatus: "",
        errorMsg:
          error.response.data.message ||
          "Something went wrong! Please try again",
      });
      return {};
    }
  };

  handleWithdraw = async () => {
    this.setState({ transactionStatus: TRANSACTION_STATUS_TYPE.loading });
    const { amount, orderId, signature, token } =
      await this.handleGetOrderData();

    if (!orderId) {
      this.setState({ transactionStatus: "" });
      return;
    }

    try {
      const data = depositWithdrawInstance.interface.encodeFunctionData(
        "withdraw",
        [orderId, token, amount.toString(), signature]
      );

      const transactionData = {
        from: this.state.currentAddress,
        data: data,
        to: process.env.REACT_APP_ADDRESS_DEPOSIT_WITHDRAW,
      };

      const transactionHash = await handleSendTransaction({
        transactionData: transactionData,
        walletAddress: this.state.currentAddress,
      });

      const transactionResult = await handleGetResult(transactionHash);

      if (transactionResult.status === TRANSACTION_STATUS_TYPE.complete) {
        this.setState({
          transactionStatus: TRANSACTION_STATUS_TYPE.complete,
        });
      } else if (transactionResult.status === TRANSACTION_STATUS_TYPE.failed) {
        this.setState({
          transactionStatus: TRANSACTION_STATUS_TYPE.failed,
        });
      }

      this.setState({ amountInput: "", openSnack: true });
    } catch (error) {
      console.log(error);
      this.setState({
        transactionStatus: TRANSACTION_STATUS_TYPE.failed,
        amountInput: "",
        openSnack: true,
      });
    }
  };

  handleGetHistory = async () => {
    const token = localStorage.getItem("token");
    const getHistoryDataUrl = `${process.env.REACT_APP_SERVICE_URL}/withdraw/list-order`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = {};

    try {
      response = await axios.post(getHistoryDataUrl, {}, config);

      if (response.status === 200) {
        this.setState({ pendingTransaction: response.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleCancelTransaction = async (orderId) => {
    if (!orderId) return;

    this.setState({ transactionStatus: TRANSACTION_STATUS_TYPE.loading });

    const token = localStorage.getItem("token");
    const getHistoryDataUrl = `${process.env.REACT_APP_SERVICE_URL}/withdraw/cancel-order`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = {};

    try {
      response = await axios.post(
        getHistoryDataUrl,
        {
          orderId: orderId,
        },
        config
      );

      if (response.status === 200) {
        const { orderId, token, signature } = response.data;

        const data = await depositWithdrawInstance.interface.encodeFunctionData(
          "cancel",
          [orderId, token, signature]
        );

        const transactionData = {
          from: this.state.currentAddress,
          data: data,
          to: process.env.REACT_APP_ADDRESS_DEPOSIT_WITHDRAW,
        };

        const transactionHash = await handleSendTransaction({
          transactionData: transactionData,
          walletAddress: this.state.currentAddress,
        });

        const transactionResult = await handleGetResult(transactionHash);

        if (transactionResult.status === TRANSACTION_STATUS_TYPE.complete) {
          this.setState({
            transactionStatus: TRANSACTION_STATUS_TYPE.complete,
          });
        } else if (
          transactionResult.status === TRANSACTION_STATUS_TYPE.failed
        ) {
          this.setState({
            transactionStatus: TRANSACTION_STATUS_TYPE.failed,
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({
        transactionStatus: TRANSACTION_STATUS_TYPE.failed,
      });
    }
  };

  setUpCoins = () => {
    if (isMobile()) {
      this.setState({ height: 685, margin: "mt-1" });
    }

    coins.forEach((item, i) => {
      if (item.preffix === "NC") return;

      let list = (
        <Dropdown.Item
          key={__.toString(i)}
          as={"button"}
          className={"animated fadeIn"}
          onClick={(e) => this.loadAddress(item.active, item.preffix)}
        >
          <span>
            <img
              src={"/assets/images/" + item.image}
              className={"img-fluid mini-coin mr-1"}
              alt="Coin"
            />
            {item.preffix}
          </span>
        </Dropdown.Item>
      );

      this.setState((state) => ({ list: [list, ...state.list] }));
    });
  };

  handleClose = () => {
    this.setState({ openSnack: false, transactionStatus: "" });
  };

  render() {
    const isDeposit = this.state.tabValue === 0;

    return (
      <Box className="revi">
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
            marginTop: 32,
          }}
        >
          <Tabs
            value={this.state.tabValue}
            onChange={(_, newValue) => {
              this.setState({ tabValue: newValue, currentCoin: "ALPH" });
              this.getDefaultBalance();
              this.handleGetHistory();
            }}
          >
            <Tab
              icon={
                <SavingsIcon
                  sx={{
                    widows: 16,
                    height: 16,
                    color: isDeposit ? "#fff" : "#b2b5be",
                  }}
                />
              }
              iconPosition="start"
              label={isDeposit ? "Deposit" : ""}
              disableRipple
              style={{
                fontSize: 16,
                textTransform: "capitalize",
                backgroundColor: "#1e2024",
                borderRadius: 10,
                minWidth: isDeposit ? 112 : 48,
                marginRight: 8,
              }}
            />
            <Tab
              icon={
                <InventoryIcon
                  sx={{
                    width: 16,
                    height: 16,
                    color: !isDeposit ? "#fff" : "#b2b5be",
                  }}
                />
              }
              iconPosition="start"
              label={!isDeposit ? "Withdraw" : ""}
              disableRipple
              style={{
                fontSize: 16,
                textTransform: "capitalize",
                backgroundColor: "#1e2024",
                borderRadius: 10,
                minWidth: !isDeposit ? 112 : 48,
              }}
            />
          </Tabs>
        </Box>
        <Box className="wallet-content">
          <Box
            className="wallet-input"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              display: "flex",
              paddingLeft: 4,
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Dropdown
              bottom="true"
              style={{
                backgroundColor: "#1e2024",
                borderRadius: 18,
                width: 135,
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Dropdown.Toggle
                split
                align="end"
                variant="mt-2 text-white"
                id="dropdown-split-coins"
                style={{ width: "100%" }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 0,
                  }}
                >
                  <Box
                    style={{
                      fontSize: 16,
                      color: "#b2b5be",
                      fontWeight: "bold",
                    }}
                  >
                    <img
                      src={"/assets/images/" + this.state.currentCoin + ".png"}
                      className={"img-fluid mini-coin-12 mr-2"}
                      alt="Coin"
                    />
                    {this.state.currentCoin}
                  </Box>
                  <Box className="ml-1 nav-user-name">
                    <Box className="caret"></Box>
                  </Box>
                </Box>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={"droper clist-coins user-dropdown-detail"}
              >
                {this.state.list}
              </Dropdown.Menu>
            </Dropdown>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  color: "#b2b5be",
                  marginRight: 8,
                  marginBottom: 0,
                  fontSize: 14,
                }}
              >
                {isDeposit ? "Token Balance" : "Pool Balance"}
              </Typography>
              <Typography
                style={{
                  color: "#fff",
                  marginRight: 8,
                  marginBottom: 0,
                  textAlign: "right",
                  fontSize: 14,
                }}
              >
                {isDeposit
                  ? this.state.addressTokenBalance
                  : this.state.currentTokenCreditsBalance}
              </Typography>
            </Box>
          </Box>
          <Box style={{ width: "100%", marginTop: 24 }}>
            <Typography
              style={{ color: "#b2b5be", fontSize: 16, marginBottom: 4 }}
            >
              {isDeposit ? "Deposit amount" : "Withdraw amount"}
            </Typography>
            <input
              type="number"
              placeholder="0.000000000"
              value={this.state.amountInput}
              onChange={this.handleChangeInput}
              className="wallet-input amount-input"
            />
          </Box>
          <Box
            style={{
              marginTop: 24,
              display: "flex",
              marginBottom: 16,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Typography
              style={{ fontSize: 14, color: "#b2b5be", marginRight: 4 }}
            >
              Fee
            </Typography>
            <Typography style={{ color: "#4ade80", fontSize: 14 }}>
              {isDeposit ? "0.00" : "0%"}
            </Typography>
          </Box>
          <Button
            className="deposit-withdraw-button"
            onClick={isDeposit ? this.handleClickDeposit : this.handleWithdraw}
            disabled={
              this.state.transactionStatus === TRANSACTION_STATUS_TYPE.loading
              //||
              // !this.state.addressTokenBalanceWei
            }
          >
            {this.state.transactionStatus === TRANSACTION_STATUS_TYPE.loading
              ? "Processing"
              : isDeposit
              ? "Deposit"
              : "Withdraw"}
          </Button>
          <Typography style={{ fontSize: 16, color: "red" }}>
            {this.state.errorMsg}
          </Typography>

          {!isDeposit && (
            <Box style={{ width: "100%", marginTop: 24 }}>
              <Typography
                style={{ color: "#b2b5be", fontSize: 16, marginBottom: 4 }}
              >
                Pending Transaction History
              </Typography>
              {this.state.pendingTransaction.length > 0 ? (
                <Box style={{ width: "100%" }}>
                  <Box style={{ width: "100%", display: "flex" }}>
                    <Typography sx={{ width: "12%" }}>Amount</Typography>
                    <Typography sx={{ width: "50%" }}>OrderId</Typography>
                    <Typography sx={{ width: "22%" }}>Token Name</Typography>
                  </Box>

                  {this.state.pendingTransaction.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        style={{
                          width: "100%",
                          marginTop: 8,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ width: "12%" }}>
                          {convertTokenBalanceString(item.amount.toString())}
                        </Typography>
                        <Typography sx={{ width: "50%" }}>
                          {item.orderId}
                        </Typography>
                        <Typography sx={{ width: "22%" }}>
                          {item.token.toUpperCase()}
                        </Typography>
                        <Button
                          variant="outline"
                          className="cancel-transaction-btn"
                          onClick={() =>
                            this.handleCancelTransaction(item.orderId)
                          }
                          disabled={
                            this.state.transactionStatus ===
                            TRANSACTION_STATUS_TYPE.loading
                          }
                        >
                          Cancel
                        </Button>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Typography style={{ fontSize: 12, color: "#b2b5be" }}>
                  No pending transaction
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Snackbar
          autoHideDuration={3000}
          onClose={this.handleClose}
          open={this.state.openSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ backgroundColor: "#000", borderRadius: 8, minWidth: 260 }}
        >
          <Alert
            sx={{ color: "#fff" }}
            onClose={this.handleClose}
            icon={<CheckCircleIcon fontSize="inherit" />}
            severity={
              this.state.transactionStatus === TRANSACTION_STATUS_TYPE.failed
                ? "error"
                : "success"
            }
          >
            {this.state.transactionStatus === TRANSACTION_STATUS_TYPE.failed
              ? "Transaction Failed"
              : "Transaction Success. Please wait few minutes!"}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default DepositAndWithdraw;
