import React from 'react';
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SITE_URL, isMobile } from "../../../../Helper";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: false
        }
    }

    componentWillMount() {
        if (isMobile()) {
            this.setState({ mobile: true })
        }
    }

    render() {

        if (this.state.mobile) {

            return (
                <div>
                    <div className="bgame-footer font-light bg-footer dtw">
                        <Row className="align-items-start no-gutters">
                            <Col className="col-12  text-center">
                                <img src="/assets/images/lg.png" alt="logo" className="img-fluid" style={{ width: 80, verticalAlign: 'middle' }} />
                                <br />
                                <h1 className="mt-0 text-white font-15 d-inline-block">
                                    Dare to Dream Play
                                </h1>
                                <p className="mt-2">If gambling is becoming an issue for you</p>
                                <p>Please contact our friendly staff via our chat</p>
                            </Col>
                            <Col className="col-12">
                                <Row className="text-center mt-3 mb-4">
                                    <Col className="col-4">
                                        <li>
                                            <Link to="/"><i className="mdi mdi-circle font-11" /> Home</Link>
                                        </li>
                                    </Col>
                                    <Col className="col-4">
                                        <li>
                                            <Link to="/about-us"><i className="mdi mdi-circle font-11" /> About Us</Link>
                                        </li>
                                    </Col>
                                    <Col className="col-4">
                                        <li>
                                            <Link to={'/rules?tab=0'}><i className="mdi mdi-circle font-11" /> Privacy</Link>
                                        </li>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className="ycenter col-12 text-left">
                                <div className="footer-brands">
                                    <a href="https://cryptogambling.org/" target="_blank">
                                        <img className="img-v" src="/assets/images/verfy.svg" alt="Coin" />
                                    </a>
                                    <img className="img-18" src="/assets/images/18.svg" alt="Coin" />
                                </div>
                                <div className="footer-coin">
                                    <p className="mt-4">Accepted Networks</p>
                                    <img className="img-fluid" src="/assets/images/alph-coin.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/eth.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/tron.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/btc.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/ltc.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/bch.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/tron.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/doge.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/usdt.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/mkr.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/busd.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/usdc.png" alt="Coin" />
                                    <img className="img-fluid" src="/assets/images/tusd.png" alt="Coin" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} className="text-center">
                                <span className="mb-3 mt-4 d-block text-upper text-white">@2024 {SITE_URL} - All rights reserved</span>
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="bgame-footer font-light bg-footer dtw">
                    <Row className="align-items-start no-gutters">
                        <Col md={4} className="col-6 pl-2">
                            <img src="/assets/images/lg.png" alt="logo" className="img-fluid" style={{ width: 80, verticalAlign: 'middle' }} />
                            <h1 className="mt-0 text-white font-15 d-inline-block">
                                Discovery to Dream 
                            </h1>
                            <p className="mt-2">If trust in gambling is becoming an issue for you</p>
                            <p>Trust Web.3 and Verified Smart Contracts</p>
                        </Col>
                        <Col md={4} className="col-6">
                            <ul className="mt-4">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to={'/rules?tab=0'}>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/rules?tab=1'}>
                                        User Agreement
                                    </Link>
                                </li>
                            </ul>
                        </Col>

                        <Col md={4} className="ycenter col-12 text-left">
                            <div className="footer-brands">
                                <a href="https://cryptogambling.org/" target="_blank">
                                    <img className="img-v" src="/assets/images/verfy.svg" alt="Coin" />
                                </a>
                                <img className="img-18" src="/assets/images/18.svg" alt="Coin" />
                            </div>
                            <p className="mt-3">Accepted Currency</p>
                            <div className="footer-coin">
                                <img className="img-fluid" src="/assets/images/alph-coin.png" alt="Coin" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} className="text-left">
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} className="text-center">
                            <span className="my-3 d-block text-upper">@2024 {SITE_URL} - All rights reserved</span>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
