import React from "react";

export default class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <h1 className="text-white font-bold mb-4">Privacy Policy</h1>
        <p className="text-white font-light mb-4 font-14 l30">
          <strong>Effective Date:</strong> 2024 DEC
          <br />
          Welcome to Alpha Casino, a Web3 casino operating on the <b>Alph Network Blockchain</b>. We are committed to protecting your privacy and ensuring your personal data is managed responsibly and securely. This Privacy Policy explains how we collect, use, disclose, and protect your information while you engage with our services and games.
        </p>

        {/* Section 1 */}
        <h2 className="text-yellow mt-4">1. Information We Collect</h2>
        <p className="text-white mb-3">
          To provide access to our services and games, we may collect the following types of information:
        </p>
        <ul className="text-white mb-4 pl-4">
          <li>
            **Wallet Information**: Public wallet addresses used for signing and playing games.
          </li>
          <li>
            **Transaction Data**: Game-related blockchain transactions for provably fair gameplay.
          </li>
          <li>
            **Account Information**: If required, email addresses or other identifiers for support purposes.
          </li>
          <li>
            **Technical Data**: Cookies, IP addresses, browser types, and device information for improving performance.
          </li>
        </ul>

        {/* Section 2 */}
        <h2 className="text-yellow mt-4">2. Use of Your Information</h2>
        <p className="text-white mb-4">
          Your data is used to:
          <br />
          - Enable you to play games and sign transactions securely.<br />
          - Provide provably fair gaming experiences on the blockchain.<br />
          - Monitor and improve our platform’s performance.<br />
          - Notify you about service updates, promotions, or relevant changes.<br />
          - Ensure compliance with legal requirements.<br />
          If you prefer not to receive marketing emails or notifications, you can opt out anytime.
        </p>

        {/* Section 3 */}
        <h2 className="text-yellow mt-4">3. Blockchain Transparency</h2>
        <p className="text-white mb-4">
          Transactions and wallet interactions made on Alpha Casino are recorded publicly on the <b>Alph Network Blockchain</b>. While this ensures transparency and fairness, it also means certain data (e.g., wallet addresses, transaction hashes) will be publicly visible and cannot be altered or deleted.
        </p>

        {/* Section 4 */}
        <h2 className="text-yellow mt-4">4. Cookies and Tracking Technologies</h2>
        <p className="text-white mb-4">
          We use cookies to enhance your browsing experience. Cookies help us:<br />
          - Keep track of your sessions.<br />
          - Understand how you interact with our platform.<br />
          - Improve user experience and platform performance.<br />
          If you wish to manage or disable cookies, you can do so in your browser settings.
        </p>

        {/* Section 5 */}
        <h2 className="text-yellow mt-4">5. Security and Data Protection</h2>
        <p className="text-white mb-4">
          We take the protection of your data seriously and implement the following security measures:
        </p>
        <ul className="text-white mb-4 pl-4">
          <li>End-to-end encryption for wallet and server interactions.</li>
          <li>Firewalls, VPNs, and restricted access protocols for servers.</li>
          <li>Encrypted backups and DDoS mitigation strategies.</li>
          <li>Secure node connections through VPN tunnels.</li>
          <li>Cloudflare protection for all HTTPS services.</li>
        </ul>

        {/* Section 6 */}
        <h2 className="text-yellow mt-4">6. Sharing Your Information</h2>
        <p className="text-white mb-4">
          We only share your information when necessary:<br />
          - With trusted service providers (e.g., Zendesk for support).<br />
          - To comply with legal requirements or respond to lawful authorities.<br />
          - To improve our platform functionality and user experience.<br />
          We do not sell or rent your personal data to third parties.
        </p>

        {/* Section 7 */}
        <h2 className="text-yellow mt-4">7. Data Breach Notifications</h2>
        <p className="text-white mb-4">
          In the event of a personal data breach, we will notify affected users promptly, as required by the <b>GDPR</b> and applicable data protection laws.
        </p>

        {/* Section 8 */}
        <h2 className="text-yellow mt-4">8. International Data Transfers</h2>
        <p className="text-white mb-4">
          Your data may be transferred to trusted service providers outside your country of residence for operational purposes. We ensure such transfers comply with appropriate data protection safeguards.
        </p>

        {/* Section 9 */}
        <h2 className="text-yellow mt-4">9. Your Rights</h2>
        <p className="text-white mb-4">
          You have the right to:<br />
          - Access your personal data.<br />
          - Correct inaccuracies in your data.<br />
          - Request data deletion, where applicable.<br />
          - Opt out of marketing communications.<br />
          - Manage cookie preferences.
        </p>

        {/* Section 10 */}
        <h2 className="text-yellow mt-4">10. Contact Us</h2>
        <p className="text-white mb-4">
          If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact our support team at <b>support@alpha.casino</b>.
        </p>

        <p className="text-white mt-4 font-italic">
          By using Alpha Casino, you agree to this Privacy Policy. We reserve the right to update this policy periodically, and any changes will be reflected on this page.
        </p>
      </>
    );
  }
}
